import './App.css';
import logo from './logo.jpg';
import zkUrl from './image/zk.png';
// import { OverPack } from 'rc-scroll-anim';
// import QueueAnim from 'rc-queue-anim';
import { useEffect, useState } from 'react';
import Timeline from './Timeline';

function App() {
  const [active, setActive] = useState(1);
  const [click, setClick] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [zk, setZk] = useState(false);

  // animate
  useEffect(() => {
    document.addEventListener('scroll', () => setScroll(true));
  }, []);
  useEffect(() => {
    if (click || !scroll) {
      setScroll(false);
      return;
    }
    const currentScroll = document.documentElement.scrollTop;
    const h2 = document.getElementById('section2')?.offsetTop || 0;
    const h3 = document.getElementById('section3')?.offsetTop || 0;
    const h4 = document.getElementById('section4')?.offsetTop || 0;
    const h5 = document.getElementById('section5')?.offsetTop || 0;
    if (currentScroll < h2) {
      setActive(1);
      setScroll(false);
      return;
    }
    if (currentScroll < h3) {
      setActive(2);
      setScroll(false);
      return;
    }
    if (currentScroll < h4) {
      setActive(3);
      setScroll(false);
      return;
    }
    if (currentScroll < h5) {
      setActive(4);
      setScroll(false);
      return;
    }
    setActive(5);
    setScroll(false);
  }, [click, scroll]);
  useEffect(() => {
    if (!click || scroll) {
      setClick(false);
      return;
    }
    switch (active) {
      case 1:
        document.getElementById('section1')?.scrollIntoView();
        break;
      case 2:
        document.getElementById('section2')?.scrollIntoView();
        break;
      case 3:
        document.getElementById('section3')?.scrollIntoView();
        break;
      case 4:
        document.getElementById('section4')?.scrollIntoView();
        break;
      case 5:
        document.getElementById('section5')?.scrollIntoView();
        break;
    }
    setTimeout(() => {
      setClick(false)
    }, 900)
  }, [active]);

  return (
    <>
      <header className="header">
        <div className='leftBox'>
          <a href="">
            <img className='logo' src={logo} />
          </a>
          <div className='divider'></div>
          <div>
            <p>
              <a href="http://www.cs.ucc.ie/">SCHOOL OF COMPUTER SCIENCE AND INFORMATION TECHNOLOGY</a>
            </p>
            <p>
              <a href="http://www.ucc.ie">UNIVERSITY COLLEGE CORK</a>
            </p>
          </div>
        </div>
        <img className='zk' onClick={() => setZk(z => !z)} src={zkUrl} />
        <nav className={`nav ${zk ? 'zknav' : 'bzknav'}`} >
          <p className={active === 1 ? 'active' : ''} onClick={() => {
            setActive(1)
            setClick(true)
            setZk(false)
          }}>INFORMATION</p>
          <p className={active === 2 ? 'active' : ''} onClick={() => {
            setActive(2)
            setClick(true)
            setZk(false)
          }}>DATES</p>
          <p className={active === 3 ? 'active' : ''} onClick={() => {
            setActive(3)
            setClick(true)
            setZk(false)
          }}>SUBMIT</p>
          <p className={active === 4 ? 'active' : ''} onClick={() => {
            setActive(4)
            setClick(true)
            setZk(false)
          }}>ARCHIVE</p>
          <p className={active === 5 ? 'active' : ''} onClick={() => {
            setActive(5)
            setClick(true)
            setZk(false)
          }}>CONTACT</p>
        </nav>
      </header>
      <section className='bannerbox'>
        <h1>Welcome to AIPO Competition</h1>
        {/* <p> <a href="./pdf/AIPO2024_Prelim.pdf">2024 AIPO Preliminary Round Problems</a> have been released (final version)</p> */}
        <p>The submission <span style={{ color: "#ffd452" }}>deadline</span> for the preliminary round problems is <span style={{ color: "#ffd452" }}>19th January 2025</span></p>
        <p>Also available are some <a href="./pdf/AIPO_Tips___Tricks.pdf" style={{ color: "#ffd452" }}>tips</a> for solving problems and submitting to the server</p>
      </section>
      <div className='content-box'>
        <section className='page1' id="section1">
          <div className='common-box'>
            <h1 className='marginSmall'>General Information</h1>
            <h2>An annual computer programming olympiad held at University College Cork (UCC).</h2>
            {/* on the 1st of July */}
            <p>The All Ireland Programming Olympiad(AIPO) Competition is open to all second-level students in Ireland who are under 20. AIPO aims to achieve the following:</p>
            <p style={{ paddingTop: "14px", margin: 0, fontWeight: 500 }}>AIPO aims to achieve the following:</p>
            <ol className='textUl'>
              <li>To promote contacts among programmers in Ireland.</li>
              <li>To encourage and reward young programmers, (male and female).</li>
              <li>To select a team for the International Olympiad in Informatics (IOI) and for the European Computer Science Olympiad for Girls.</li>
            </ol>
            <p style={{ paddingTop: "14px", margin: 0, fontWeight: 500 }}>It consists of two rounds: </p>
            <ol className='textUl'>
              <li>The First Round can be done at home or at school and is used to determine the entry to the final round, which will be held in the School of Computer Science and Information Technology at UCC. </li>
              <li>The AIPO Final Round will take online on the <span style={{ color: "#1aab91" }}>15th February 2025</span>.</li>
            </ol>
          </div>
        </section>
        <section className='page1 page2' id="section2">
          <div className='common-box'>
            <h1 className='marginSmall'>AIPO Important Dates</h1>
            <h2>The keys dates for the 2024 AIPO Olympiad</h2>
            <Timeline data={[
              { time: '20th December 2024', value: 'Preliminary Round Problems' },
              { time: '19th January 2025', value: 'Submission deadline for the the preliminary round problems' },
              { time: '15th February 2025', value: 'AIPO Final (online)' },
              { time: '1st March 2025', value: 'IrlCPC (in person)' },
            ]} />
          </div>
        </section>
        <section className='page1' id="section3">
          <div className='common-box'>
            <h1>How to Submit</h1>
            <h2>Submitting Solutions</h2>
            <p>First, head to <a href='' style={{ color: "#1aab91" }}>registration server</a> to sign up by filling the g-form provided. Make sure you fill out both the registration Google form, as well as the server credential registration page (link at the top of the form). Once you have registered, you can use the credentials you created on the server to login to the server and begin making submissions.</p>
            <p>Each of the 5 problems has an entry on the right. Click the "Submissions" link below the appropriate question to get to the question page. From here, you can upload your solution and have it evaluated by the server. Within a minute, you should get a score from the system; each passed test case gets you 10 points, and the test cases are progressively harder.</p>
            <p>You can submit solutions as many times as you like, but only the most recently submitted solution will count towards your final score.</p>
            <p>The registration and submission will be functional from <span style={{ color: "#1aab91" }}>Friday 20th December 2024</span>.</p>
            <p>If you have any problems logging in, forgotten usernames or passwords, or with the system in general, please contact Andrew Nash <a type='email' href='mailto:anash@umail.ucc.ie'>anash@umail.ucc.ie</a></p >
          </div>
        </section>
        <section className='page1 page2' id="section4">
          <div className='common-box'>
            <h1>AIPO Archive</h1>
            <h2>Previous Problems</h2>
            <div className='columns2Box'>
              <ul className='columns2'>
                <li>
                  <a href="">  </a>
                  <a href="./pdf/AIPO2024_Prelim.pdf">2024 AIPO Preliminary Round Problems</a>
                  <div className='clear'></div>
                </li>
                <li>
                  <a href="">  </a>
                  <a href="./pdf/AIPO2023_Prelim.pdf">2023 AIPO Preliminary Round Problems</a>
                  <div className='clear'></div>
                </li>
                <li>
                  <a href="./pdf/AIPO2022_Finals.pdf">2022 AIPO National Finals Problems</a>
                  <a href="./pdf/AIPO2022_Prelim.pdf">2022 AIPO Preliminary Round Problems</a>
                  <div className='clear'></div>
                </li>
                <li>
                  <a href="./pdf/AIPO2021_Finals.pdf">2021 AIPO National Finals Problems</a>
                  <a href="./pdf/AIPO2021_Prelim.pdf">2021 AIPO Preliminary Round Problems</a>
                  <div className='clear'></div>
                </li>
                <li>
                  <a href="./pdf/AIPO2020_Finals.pdf">2020 AIPO National Finals Problems</a>
                  <a href=""> </a>
                  <div className='clear'></div>
                </li>
                <li>
                  <a href="./pdf/AIPO2018_Finals.pdf">2018 AIPO National Finals Problems</a>
                  <a href="./pdf/AIPO2018_Prelim.pdf">2018 AIPO Preliminary Round Problems</a>
                  <div className='clear'></div>
                </li>
                <li>
                  <a href="./pdf/AIPO2017_Finals.pdf">2017 AIPO National Finals Problems</a>
                  <a href="./pdf/AIPO2017_Prelim.pdf">2017 AIPO Preliminary Round Problems</a>
                  <div className='clear'></div>
                </li>
                <li>
                  <a href="./pdf/AIPO2016_Finals.pdf">2016 AIPO National Finals Problems</a>
                  <a href="./pdf/AIPO2016_Prelim.pdf">2016 AIPO Preliminary Round Problems</a>
                  <div className='clear'></div>
                </li>
                <li>
                  <a href="./pdf/AIPO2015_Finals.pdf">2015 AIPO National Finals Problems</a>
                  <a href="./pdf/AIPO2015_Prelim.pdf">2015 AIPO Preliminary Round Problems</a>
                  <div className='clear'></div>
                </li>
                <li>
                  <a href="./pdf/AIPO2014_Finals.pdf">2014 AIPO National Finals Problems</a>
                  <a href="./pdf/AIPO2014_Prelim.pdf">2014 AIPO Preliminary Round Problems</a>
                  <div className='clear'></div>
                </li>
                <li>
                  <a href="./pdf/AIPO2013_Finals.pdf">2013 AIPO National Finals Problems</a>
                  <a href="./pdf/AIPO2013_Prelim.pdf">2013 AIPO Preliminary Round Problems</a>
                  <div className='clear'></div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className='page1' id="section5">
          <div className='common-box'>
            <h1>Contact Details</h1>
            <h2>You can contact the AIPO team</h2>
            <ol className='olmedia'>
              <li>Prof. Utz Röedig, Head of School of CSIT, UCC<span>Chairperson</span></li>
              <li>Dr. Sabin Tabirca, UCC<span><a type='email' href='mailto:tabirca@cs.ucc.ie'>tabirca@cs.ucc.ie</a></span><span>Coordinator - General Questions</span></li>
              <li>Andrew Nash, UCC<span><a type='email' href='mailto:anash@umail.ucc.ie'>anash@umail.ucc.ie</a></span><span>Member - Questions on the registration and submission servers</span></li>
              <li>Bogdan Marcu, UCC<span><a type='email' href='mailto:bogdanstefanmarcu@yahoo.com'>bogdanstefanmarcu@yahoo.com</a></span><span>Member - Questions on the AIPO problems</span></li>
              <li>Yanlin Mi, CRT-AI Center, UCC<span><a type='email' href='mailto:120220407@umail.ucc.ie'>y.mi@cs.ucc.ie</a></span><span>Member - Questions on the AIPO Webpage</span></li>
              <li>Andrea Balogh, Confirm Center, UCC<span><a type='email' href='mailto:a.balogh@cs.ucc.ie'>a.balogh@cs.ucc.ie</a></span><span>Member - Questions on the AIPO problems</span></li>
            </ol>
          </div>
        </section>
      </div>
      <footer className='footer'>
        © {new Date().getFullYear()}. All Ireland Programming Olympiad. Technical Support by <a style={{
          color: '#ccc'
        }} href="mailto:y.mi@cs.ucc.ie">Yanlin Mi</a>.
      </footer>
    </>
  );
}

export default App;
